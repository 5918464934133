<template>
    <section>
        <div v-if="geocerca" >
            <button
                type="button"
                class="btn-outline mx-2 d-flex a-center j-center"
                @click="direcciones"
                v-text="'Mis direcciones'"
                />
        </div>
        <div v-if="!geocerca">
            <div class="d-middle tres-puntos border br-10 py-1" @click="direcciones">
                <i class="icon-map-pin text-gris2 f-18" />
                <span v-if="usuario.direccion" class="f-14 tres-puntos f-400 text-gris2">
                    {{ usuario.direccion.direccion }}, {{ usuario.direccion.direccion_anexo }}
                </span>
                <span v-else class="f-14 tres-puntos f-400 text-gris2">
                    Sin dirección
                </span>
                <i class="icon-chevron-down text-gris2 f-20" />
            </div>
            <div v-show="cobertura == false" class="row mx-0 j-center mt-3">
                <div class="alert-cobertura tres-puntos d-middle">
                    <i class="icon-alert-circle-outline f-18" />
                    <div class="col tres-puntos pl-0 pr-2 f-12">
                        No tenemos con cobertura en tu área
                    </div>
                </div>
            </div>
        </div>
        <modal-direcciones ref="modalDirecciones" @acciones="acciones" @editarDireccion="irEditarDireccion" @cobertura="updateCobertura" @update="updateTiendas"/>
        <modal-nueva-direccion ref="modalNuevaDireccion" @update="updateTiendas"/>
        <modal-editar-direccion ref="modalEditarDireccion" @actualizar="updateTiendas" />
        <modal ref="confirmarEliminarDireccion" titulo="Eliminar Vendedor" no-aceptar adicional="Eliminar" @adicional="deleteDireccion">
            <div class="row mx-0 j-center">
                <p>
                    ¿Estás seguro de eliminar esta dirección?
                </p>
            </div>
        </modal>
    </section>
</template>
<script>
import {mapGetters} from 'vuex'
export default {
    components: {
        modalDirecciones: () => import('../pages/direcciones/partials/modalDirecciones'),
        modalNuevaDireccion: () => import('../pages/direcciones/partials/modalNuevaDireccion'),
        modalEditarDireccion: () => import('../pages/direcciones/partials/modalEditarDireccion'),

    },
    props:{
        geocerca:{ // Se usa en la vista de sin-cobertura aplica para mxm
            type:Boolean,
            default:false
        }
    },
    data(){
        return {
            cobertura:true
        }
    },
    computed: {
        ...mapGetters({
            usuario: 'auth/obtenerUsuario',
        }),
    },
    methods:{
        direcciones(){
            this.$refs.modalDirecciones.toggle('normal');
        },
        async acciones(bandera){
            switch(bandera){
            case "eliminarDireccion":
                return this.$refs.confirmarEliminarDireccion.toggle();
            case "nuevaDireccion":
                return this.$refs.modalNuevaDireccion.toggle();
            }
        },

        irEditarDireccion(data){
            this.$refs.modalEditarDireccion.toggle(data);
        },

        async deleteDireccion(){
            this.$refs.confirmarEliminarDireccion.toggle();
            this.$refs.modalDirecciones.toggle('eliminar');
        },
        updateCobertura(cobertura){
            this.cobertura = cobertura
        },
        async updateTiendas(){
            try {
                const route = this.$route.name
                this.$refs.modalDirecciones.initDirecciones();
                if(route == 'home'){
                    const response = await this.$store.dispatch(`asignarTiendas/asignarTiendas`)
                    if(!response.exito){
                        this.$router.push({name:'sin-cobertura'})
                        return
                    }
                    this.$store.dispatch('home/getHome')
                }else{
                    this.$router.push({name:'home'})
                }
            } catch (error){
                this.errorCatch(error)
            }
        },
    }
}
</script>
<style lang="scss" scoped>
.alert-cobertura{
        height: 30px;
        border-radius: 15px;
        color: #FF5806;
        border: 1px solid #FF5806;
}

</style